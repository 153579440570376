@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --dark-grey: #111827;
  --light-grey: #dadde3;
}

body,
.active {
  background-color: var(--dark-grey);
}

body {
  color: var(--light-grey);
}
